.img-cont {
  background-image: url("../../../assets/images/about.png");
  background-repeat: no-repeat;

  box-sizing: border-box;
  height: 785px;
  width: 645px;
  margin: 25px auto;
  border: 7px solid var(--color-primary);
  border-radius: 50px;
  z-index: 1;
}

.about-title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 126%;
  /* or 55px */

  font-feature-settings: "pnum" on, "lnum" on;

  color: var(--color-primary);
  text-align: left;
  margin-left: 15%;
  margin-top: 15px;
}

.about-subtitle {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  margin-left: 15%;
  margin-top: 40px;
  line-height: 126%;
  /* or 45px */

  font-feature-settings: "pnum" on, "lnum" on;

  color: #5c5c5f;
}

.about-content ul {
  margin-top: 50px;
}

.about-content li {
  margin: 18px;
  font-size: 20px;
  color: #616161;
  font-family: Rubik;
  max-width: 700px;
  margin-left: 10%;
}

.about-content li::marker {
  font-size: 25px;
}

@media only screen and (max-width: 767px) {
  .hiden-sm {
    display: none;
  }
  .contact .row {
    width: 100vw;
    margin: auto;
  }
}
