/* ************** Time Picker  **************
.css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
  font-weight: bold;
  height: 40px;
  cursor: pointer;
  padding: 0.25em 1em;
  border-radius: 3px;
  text-align: center;
  min-width: 100px !important;
  border: inherit !important;
  border-radius: 14px !important;
  box-shadow: rgb(107 114 128 / 18%) -3px 1px 5px 2px;
  background-color: inherit;
  color: black;
  margin-right: 5px;
  margin-bottom: 10px;
  float: left;
}
.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected:hover,
.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: white !important;
  background-color: #5c5c5f !important;
  border: inherit !important;
  border-radius: 14px !important;
  border-color: none !important;
}
.css-1gjgmky-MuiToggleButtonGroup-root {
  flex-wrap: wrap;
} */
/*************** End Time Picker  ***************/

.custom-toggle-button-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.custom-toggle-button {
  font-weight: bold;
  color: black;
  cursor: pointer;
  text-align: center;
  min-width: 90px !important;
  border: inherit !important;
  border-radius: 14px !important;
  box-shadow: rgb(107 114 128 / 18%) -3px 1px 5px 2px;
  background-color: inherit;
  margin-right: 5px;
  margin-bottom: 10px;
}

.custom-break-line-1 {
  display: block;
  margin: 0.5rem;
}

.custom-break-line-2 {
  display: block;
  margin: 1rem;
}

.custom-break-line-3 {
  display: block;
  margin: 1.5rem;
}

.custom-toggle-button.Mui-selected:hover,
.custom-toggle-button.Mui-selected {
  color: white !important;
  background-color: #5c5c5f !important;
  border: inherit !important;
  border-radius: 14px !important;
  border-color: none !important;
}

.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected:hover,
.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: white !important;
  background-color: #5c5c5f !important;
  border: inherit !important;
  border-radius: 14px !important;
  border-color: none !important;
}

@media only screen and (max-width: 500px) {
  .custom-toggle-button-group {
    justify-content: center;
  }

  .custom-toggle-button {
    min-width: 82px !important;
    border-radius: 14px !important;
    margin-bottom: 10px;
  }
}
