.cell-hover-elements-container {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
  
    > div {
      flex: 1;
      margin: 1px;
  
      &:hover::after {
        content: "+";
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: black;
      }
    }
  }