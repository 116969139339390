.big-container {
  height: 1000px;
  width: 1300px;
  margin: 100px auto;
  position: relative;
  align-items: center;
}

.img-container {
  background-image: url("../../assets/images/spt.png");

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-sizing: border-box;
  height: 800px;
  width: 620px;
  top: 100px;
  position: absolute;
  border-radius: 10px;
  z-index: 1;
}

.text2 h2 {
  text-align: left;
  margin-left: 40px;
  margin-top: 20px;
  font-size: 44px;
  font-family: Rubik;
  line-height: 126%;
  font-weight: 400;
  color: #000;
}

.text-container {
  background-color: #fff;
  height: 1000px;
  width: 1000px;
  right: 0px;
  position: absolute;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -5px;
}

.text-container .text2 {
  width: 680px;
  background-color: #fff;
  height: 1000px;
  right: 0px;
  margin: 0;
  position: absolute;
  border-radius: 10px;
}

.separator {
  height: 5px;
  width: 600px;
  background-color: #616161;
  border-radius: 5px;
  right: 40px;
  position: absolute;
  margin-top: 20px;
}

/* .content {
  margin-top: 100px;
} */

.css-11lq3yg-MuiGrid-root {
  align-items: center;
}

.content li {
  margin: 24px;
  font-size: 20px;
  color: #616161;
  font-family: Rubik;
}

.content li::marker {
  font-size: 25px;
}

@media screen and (max-width: 1300px) {
  .big-container {
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 50px auto;
  }

  .img-container {
    /* height: 800px;
        width: 375px;
        background-position: 55% 0%;
        position: initial; */

    height: 51vh;
    width: 85%;
    position: initial;
    background-size: cover;
  }

  .text-container {
    background-color: #fff;
    height: max-content;
    width: 85vw;
    right: 0px;
    position: initial;
    margin-top: 40px;
  }

  .text2 h2 {
    text-align: center;
    margin: 20px auto;
    font-size: 44px;
    font-family: Rubik;
    line-height: 126%;
    font-weight: 400;
    color: #000;
  }

  .text-container .text2 {
    width: 100%;
    background-color: #fff;
    height: 100%;
    margin: 0;
    position: relative;
    border-radius: 10px;
  }

  .separator {
    height: 5px;
    width: 80%;
    background-color: #616161;
    border-radius: 5px;
    margin: 20px auto;
    right: unset;
    position: relative;
  }

  .content {
    margin-top: 40px;
  }
}
