.edit_details {
  display: flex;
  align-items: baseline;
}
.edit_details p {
  margin-bottom: inherit;
}
.modal h5 {
  margin-right: 0.5rem;
}
/* {
  background-color: #921c45;
  border-color: #6a0416;
} */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper .annulation-btn {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  background-color: #921c45;
  border-color: #6a0416;
  text-transform: none;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper .annulation-btn:focus,
.css-1t1j96h-MuiPaper-root-MuiDialog-paper .annulation-btn:hover,
.css-1t1j96h-MuiPaper-root-MuiDialog-paper .annulation-btn:active {
  /* background-color: #87041a; */
  background-color: #5c0917;
}
.CircularProgress_cancelBooking .css-1idz92c-MuiCircularProgress-svg {
  color: #5c5c5f;
}
