.GIT-container {
  height: 550px;
  width: 800px;
  margin: 0px auto;
  position: relative;
}

.social-container {
  background-color: #6b84cf;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-sizing: border-box;
  height: 450px;
  width: 42%;
  top: 50px;
  position: absolute;
  border-radius: 10px;
  z-index: 1;
}

.text-content {
  width: 70%;
  background-color: #fff;
  height: 550px;
  right: 0px;
  margin: 0;
  position: absolute;
  padding: 20px;
}

.form-container {
  background-color: #fff;
  height: 550px;
  width: 80%;
  right: 0px;
  position: absolute;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -5px;
}

.GIT-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  color: var(--color-primary);
  margin: 20px auto;
  text-align: center;
}

.GIT-p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  color: #a2a2a2;
}

.GIT-input {
  background: rgba(102, 139, 216, 0.16);
  border-radius: 5px;
  border-color: rgba(102, 139, 216, 0.16);
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: #969696;
  padding: 10px;
}

.GIT-btn {
  margin-top: 75px;
  margin-left: 60%;
  background-color: var(--color-primary);
  border-radius: 7px;
  width: 140px;
  height: 45px;
  color: #fff;
  font-weight: bold;
}
.social-container_content {
  height: inherit;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 26px;
}
@media screen and (max-width: 800px) {
  .GIT-container {
    height: max-content;
    /* width: 100%; */
    width: auto;
    display: flex;
    flex-direction: column;
    margin: auto 10px;
    align-items: center;
  }

  .social-container {
    height: 45vh;
    width: 100%;
    position: initial;
  }

  .form-container {
    background-color: #fff;
    height: max-content;
    width: 100%;
    right: 0px;
    position: initial;
    margin-top: 40px;
  }

  .text-content {
    width: 100%;
    background-color: #fff;
    height: 100%;
    margin: 0;
    position: relative;
  }

  .GIT-btn {
    margin-top: 100px;
  }
}
