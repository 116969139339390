/*===================== 
	Premery bg color 
=====================*/

:root {
  --color-primary: #1a5689;
  --color-secondry: #5c5c5f;
  --color-hover: #5c5c5f;
}

.primary-btn {
  color: #ffffff;
  border-radius: 1rem;
  background-color: var(--color-primary);
  margin-left: inherit;
  font-size: 18px;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.primary-btn:hover {
  /* background-color: var(--color-hover); */
  background-color: #528ec1;
  color: #000000;
}

.cancel-btn {
  color: #000000;
  border-radius: 1rem;
  margin-left: inherit;
  font-size: 18px;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.cancel-btn:hover {
  background-color: #74797ec4;
}

.annulation-btn {
  color: #ffffff;

  border-radius: 1rem;
  /* background-color: #921c45; */
  background-color: #9f426c;

  margin-left: inherit;
  font-size: 18px;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.annulation-btn:hover {
  background-color: #7b495f;
}
