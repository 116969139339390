.register-container {
  width: 45%;
  height: 100%;
  background: #ffffffbf;
  box-shadow: 0px 0px 7px 3px rgb(0 0 0 / 25%);
  border-radius: 40px;
  margin: auto;
  padding: 0 2rem;
  margin-top: 16vh;
}

.white-container {
  height: 100%;
  background: #ffffff;

  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px 0px 0px 40px;
}
.blue-container {
  background: var(--color-primary);
  left: unset;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 40px 40px 0px;
}
.register-title_re {
  font-size: 48px;
  line-height: 28px;
  color: #000000;
}
.try_This_Way_Register-div {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
}
.login-title_re {
  font-size: 48px;
  line-height: 28px;
  color: #ffffff;
}
.register-p {
  font-size: 24px;
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
  overflow: unset;
}
.register-form {
  width: 100%;
}
.form-group {
  /* margin-bottom: 4px; */
  height: 42px;
}
.form-check {
  padding-left: 0px;
  float: left;
}
.forget-pswd {
  float: left;
  margin-top: 17px;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: var(--color-primary) !important;
}
.register-Image {
  height: 37vh;
}
.try-this-way {
  color: #ffffff;
}
.try-this-way:hover {
  color: #000000;
}

.register-container .form-control {
  border-radius: 1.25rem;
}

.register-title_re {
  margin-top: 2rem;
  font-size: 35px;
}

.register-form {
  width: 100%;
  margin-top: 1rem;
}

.form-group {
  margin-bottom: 0.1rem;
  height: 56px;
}

.register-btn {
  margin-top: 0rem;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 900px) {
  .register-container {
    width: 75%;
    margin-top: 16vh;
  }

  .register-title_re {
    margin-top: 2rem;
    font-size: 35px;
  }

  .register-form {
    width: 100%;
    margin-top: 1rem;
  }

  .form-group {
    margin-bottom: 0.1rem;
    height: 56px;
  }

  .register-btn {
    margin-top: 0rem;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 700px) {
  .register-container {
    width: 85%;
    margin-top: 16vh;
  }

  .register-title_re {
    margin-top: 2rem;
    font-size: 35px;
  }

  .register-form {
    width: 100%;
    margin-top: 1rem;
  }

  .form-group {
    margin-bottom: 0.1rem;
    height: 56px;
  }

  .register-btn {
    margin-top: 0rem;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 520px) {
  .register-container {
    width: 90%;
    margin-top: 12vh;
  }
}
