.login-container {
  width: 45%;
  height: 100%;
  background: #ffffffbf;
  box-shadow: 0px 0px 7px 3px rgb(0 0 0 / 25%);
  border-radius: 40px;
  margin: auto;
  padding: 0 2rem;
  margin-top: 16vh;
}

.login-btn {
  margin-top: 0.5rem;
  margin-bottom: 3rem;
}

.blue-container-login {
  height: 100%;
  background: var(--color-primary);
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px 0px 0px 40px;
}
.white-container-login {
  height: 100%;
  background-image: url("../../../assets/images/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 40px 40px 0px;
}
.register-title {
  font-size: 48px;
  line-height: 28px;
  color: #ffffff;
}

.pwd_group {
  display: flex;
  align-items: center;
  justify-content: end;
}

.pwd_group .input-group-btn {
  position: absolute;
}

.welcome-div {
  width: 100%;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
}
.login-title {
  font-size: 38px;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}
.login-p {
  height: 63px;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  overflow: unset;
}
.login-form {
  width: 100%;
  margin-top: 2rem;
}
.form-group {
  margin-bottom: 24px;
  height: 56px;
}
.form-check {
  padding-left: 0px;
  float: left;
}
.forget-pswd {
  float: left;
  margin-top: 17px;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: var(--color-primary) !important;
}
.login-Image {
  height: 37vh;
}
.try-this-way {
  color: #ffffff;
}
.try-this-way:hover {
  color: #000000;
}

.bg_img {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  filter: blur(5px);
  object-fit: cover;
}

.login-container .form-control {
  border-radius: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 900px) {
  .login-container {
    width: 60%;
  }

  .login-container .form-control {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 700px) {
  .login-container {
    width: 75%;
  }
  .login-title {
    font-size: 35px;
  }

  .login-form {
    width: 100%;
    margin-top: 2rem;
  }

  .form-group {
    margin-bottom: 24px;
    height: 56px;
  }

  .register_btn {
    margin-top: 0.9rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  .login-btn {
    margin-top: 0.5rem;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 520px) {
  .login-container {
    width: 90%;
  }
}
