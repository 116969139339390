.card-container {
  box-shadow: 0px 1px 2px 1px #0000004d !important;
  border-radius: 20px !important;
  margin: 10px 20px;
  min-width: 20vw;
  width: 25vw;
  /* max-width: 345px; */
  /* width: auto; */
}

.card-action{
  justify-content: center;
}


.card-media{
  height: 220px;
  object-fit: cover;
}

.card_date_div {
  position: relative;
  
}
.card_date_view {
  position: absolute;
  top: 10px;
  left: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #5c5c5fb3;
  padding: 7px;
  border-radius: 12px;
}
.card_date_view h6 {
  color: white;
}

.btn_subcribe {
  background: var(--color-primary);
  color: white;
}
.btn_subcribe:hover {
  color: white;
  background: var(--color-secondry);
  /* background: var(--color-hover); */
}

@media screen and (max-width: 770px) {
  .card-container {
    width: 40vw;
  }
}


@media screen and (max-width: 530px) {
  .card-container {
    width: 70vw;
  }
}
