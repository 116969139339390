.swiper-slide {
  background-position: center;
  background-size: cover;
  width: max-content;
  height: fit-content;
}
p {
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
}
/* .Card {
  display: inline-block;
} */

.card-title {
  height: 4rem;
}
.card-img,
.card-img-bottom,
.card-img-top {
  height: 17rem;
  object-fit: contain;
}
.pas_de_tournois {
  font-size: 35px;
  text-align: center;
  color: black;
  justify-content: center;
  display: flex;
  border: dashed 1px #cbcb53;
  padding: 15px 10px;
  border-radius: 20px;
}
@media screen and (max-width: 450px) {
  /* .tournament_card {
    width: 25rem;
  } */
  .container-fluid {
    flex-wrap: wrap;
  }
}
.slick-initialized .slick-slide {
  padding-left: 5px;
  padding-right: 5px;
}
