.footer {
  /* Frame 13 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;

  position: absolute;
  width: 1979.52px;
  height: 660.77px;
  left: -11.37px;
  top: 3783.19px;

  /* Vector 2 */

  position: static;
  width: 1953.66px;
  height: 622.13px;
  left: 1969.52px;
  top: 632.1px;

  background: var(--color-primary);
  transform: rotate(179.45deg);

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}

.social-curve:before {
  border-radius: 0 48px 12px 0;
  transform: skew(13deg);
}

.footer-title {
  text-align: center;
}

.footer-top,
.footer-bottom {
  color: white;
  background-color: var(--color-primary);
}

.ft-social {
  text-align: center;
}

.footer-img {
  position: absolute;
  right: -33px;
  transform: rotate(28deg);
  opacity: 7%;
  width: 20%;
}

.widget_services ul li:before {
  left: 31%;
}
@media screen and (max-width: 560px) {
  .widget_services ul li:before {
    left: 35%;
  }
}
.footer-top {
  padding: 40px 0 0;
}

.widget {
  margin-bottom: 10px;
}

.footer-top-container {
  position: relative;
}

.footer-top-ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer strong {
  color: #ebccd1;
}

.footer-top-ul li {
  width: 100%;
}

@media screen and (max-width: 560px) {
  .footer-img {
    position: absolute;
    right: 7vw;
    bottom: 60px;
    transform: rotate(28deg);
    opacity: 7%;
    width: 40%;
  }

  .footer-top-ul li {
    width: 65%;
  }
}

@media only screen and (max-width: 767px) {
  .site-footer .widget {
    display: contents;
  }
}

.site-footer .row {
  --bs-gutter-x: unset;
}

@media only screen and (max-width: 591px) {
  .social-curve {
    padding: 15px;
  }
}

.footer-bottom a {
  margin: auto 2vw;
}
