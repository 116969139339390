.bg-image {
  /* The image used */
  background-image: url("../../../assets/images/404_not_found.png");

  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(4px);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-text {
  background-color: rgb(0, 0, 0);
  background-color: rgb(0 0 0 / 40%);
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
  border-radius: 25px;
}
.bg-text h1 {
  color: white;
}
.bg-text a {
  color: inherit;
  transition: inherit;
  text-decoration: inherit;
}
