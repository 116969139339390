.bg-container {
  background-image: url("../../../assets/images/padel_home_3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 92vh;
  padding-left: 50px;
  margin-bottom: 10px;
}

.text-12 {
  font-size: 44px;
  font-weight: bold;
  text-align: left;
  color: rgb(255, 255, 255);
  display: block;
  padding-top: 13rem;
}

.start-btn1 {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  margin-top: 4rem;
}

.text-2 {
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  color: black;
  justify-content: center;
  display: flex;
}

.services {
  margin-bottom: 40px;
}

.divider {
  background-color: rgba(14, 67, 202, 0.5);
  height: 10px;
  width: 30%;
  border-radius: 20px;
  margin: 50px auto;
}

.grey {
  background-color: #e5eaf3;
  width: 100%;
  height: 300px;
  margin-top: -250px;
}

.decouvrir_tournois {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
}
.decouvrir_tournois a {
  background-color: var(--color-secondry);
  padding: 10px;
  border-radius: 10px;
  color: white;
}

@media screen and (max-width: 1000px) {
  .text-12 {
    font-size: 35px;
    padding-top: 10rem;
  }

  .start-btn1 {
    margin-top: 2rem;
  }

  .text-1 {
    font-size: 35px;
  }

  .text-2 {
    font-size: 35px;
  }
}

@media screen and (max-width: 500px) {
  .bg-container {
    height: 75vh;
    padding-left: 20px;
    margin-bottom: 10px;
    text-align: center;
  }

  .text-12 {
    font-size: 32px;
    padding-top: 8rem;
  }

  .start-btn1 {
    margin-top: 2rem;
  }

  .text-2 {
    font-size: 30px;
  }
}
