.reservations_page_header {
  background-image: url("../../../assets/images/reservation.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 23vh; /* Or '100%' depending on your needs */
  position: relative;
  margin-bottom: 10px;
}

.reservations_page_header h1 {
  position: absolute;
  top: 7vh;
  left: 11vw;
  color: white;
}

.fc {
  padding: 50px;
}

.fc .fc-button-primary {
  background-color: var(--color-primary);
}
.fc-icon-refresh {
  font: normal normal normal 14px/1 FontAwesome !important;
}
.fc-icon-refresh::before {
  content: "\f021";
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #556893;
  border-color: #556893;
}

.fc .fc-toolbar-chunk .fc-titleButton-button,
.fc .fc-toolbar-chunk .fc-titleButton-button:focus,
.fc .fc-toolbar-chunk .fc-titleButton-button:hover,
.fc .fc-toolbar-chunk .fc-titleButton-button:active,
.fc .fc-toolbar-chunk .fc-titleButton-button::selection {
  font-size: 1.75em;
  margin: 0;
  font-weight: 500;
  line-height: 1.2;
  color: #232323;
  font-family: "Poppins", sans-serif;
  background-color: inherit;
  cursor: inherit;
  border-color: white;
  border: none;
  -webkit-appearance: inherit;
}
.fc .fc-daygrid-event-harness-abs {
  width: 100%;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: unset;
}

.fc .fc-timegrid-slot {
  background-color: white;
  height: 5.5em;
  cursor: pointer;
  /* border-bottom: 0 !important; */
}

.fc-timeGridDay-view .fc-timegrid-slot:hover {
  background-color: #ebebeb !important;
}
.fc-dayGridMonth-view .fc-daygrid-day-frame {
  cursor: pointer;
}
.fc-dayGridMonth-view .fc-daygrid-day-frame:hover {
  /* .fc-dayGridMonth-view:hover { */
  background-color: #ebebeb !important;
}

.fc-timegrid-slots table {
  margin-bottom: inherit;
}
.fc b {
  color: black;
}

.fc-scroller {
  overflow-x: visible !important;
}

.modal-header .btn-close {
  background-color: #6a7797;
  position: relative;
  right: -8px;
  top: -15px;
}

.busy {
  cursor: no-drop;
  background-color: #e3a1a1;
  border-color: red;
}

.busy::after {
  /* content: 'cette période est réservée'; */
  color: black;
  position: absolute;
  top: 20%;
  right: 4%;
}

.fc-dayGridMonth-view .busy::after,
.fc-dayGridMonth-view .free::after,
.fc-timeGridWeek-view .busy::after,
.fc-timeGridWeek-view .free::after {
  content: none;
}
.fc-timeGridWeek-view .event_content .player,
.fc-dayGridMonth-view .event_content .player {
  display: none;
}

.free {
  cursor: pointer;
  background-color: #b7e7bc;
  border-color: green;
}

.fc-event:focus:after {
  content: "+";
  background-color: unset;
  bottom: unset;
  left: unset;
}

.free::after {
  /* content: '+'; */
  color: #062e0a;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 3rem;
  border-left: 1px dashed;
  width: 4%;
  height: 100%;
  display: flex;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.to_confirm {
  cursor: pointer;
}

.free div div,
.busy div div,
.to_confirm div div {
  color: black;
}

@media screen and (max-width: 560px) {
  .fc .fc-toolbar {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .fc-toolbar-chunk {
    margin-bottom: 10px;
  }

  .modal-dialog {
    left: 5%;
  }

  .fc {
    padding: 20px;
  }

  .free::after {
    width: 18%;
  }
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  background-color: rgb(28 53 110 / 10%);
  border-radius: 13px 13px 0 0;
  margin-left: 3px;
}

.spiner_div {
  display: flex;
  justify-content: center;
  margin: 7rem;
}

/* Pop up */
.modal.show .modal-dialog {
  margin-top: -10%;
}

.modal h5 {
  margin-top: 0.5rem;
}

.date-picker-class {
  padding: 6px 9px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  width: 100%;
}
.cancel_booking {
  background: inherit;
  border: inherit;
}
.cancel_booking:hover {
  color: red;
}
.event_content li {
  background-color: aliceblue;
}

.event_content .col-6 {
  padding: 1px;
}
.event_content .player {
  display: flex;
  align-items: center;
  background: #ffffffad;
  padding: 4px;
  margin-bottom: 1px;
  justify-content: center;
}

.custom-player {
  text-transform: capitalize;
}

.reservation-tabs {
  display: flex;
  flex-wrap: wrap; /* Wrap buttons into multiple rows */
  justify-content: center;
  gap: 16px; /* Space between buttons */
  padding: 10px;
}

.reservation-single-tab {
  flex: 1;
  text-transform: none;
  font-size: 16px;
  border-radius: 8px;
  transition: transform 0.2s;
  box-sizing: border-box;
  height: 48px;
}

.selected-reservation-tab {
  background-color: #d5e9fb !important;
  color: #376b97;
}

@media screen and (max-width: 768px) {
  .reservation-single-tab {
    flex: 0 1 calc(50% - 16px); /*Two buttons per row */
  }
}

@media screen and (max-width: 470px) {
  .modal-content {
    width: 90%;
  }
  .modal {
    top: -15%;
  }
}
