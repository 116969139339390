.reservations_page_header {
  background-image: url("../../../assets/images/my-reservation.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 23vh; /* Or '100%' depending on your needs */
  position: relative;
  margin-bottom: 10px;
}
