table td {
  padding: 10px 10px;
}
.aside_container {
  width: fit-content;
  right: 0px;
  height: 150vh;
  margin: auto;
}
.aside_container .vcards_containers {
  height: 100%;
  overflow-y: auto;
}

.spiner_div {
  display: flex;
  justify-content: center;
  margin: 7rem;
  color: var(--color-primary) !important;
}
.spiner_div .css-18lrjg1-MuiCircularProgress-root,
.spiner_div .css-1idz92c-MuiCircularProgress-svg {
  color: var(--color-primary) !important;
}

@media screen and (max-width: 1070px) {
  .tournament-title {
    font-size: 20px;
  }

  .tournament-heading {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    gap: 10px;
  }

  .tournament-p {
    max-width: 350px;
  }
}

.css-cpk47q-MuiGrid-root > .MuiGrid-item {
  width: 100vw;
}

.tournois_page_container {
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.slide {
  cursor: pointer;
}

.selected_event {
  background-color: #e7e5e5;
}

.pagination_div p {
  margin-bottom: 0rem;
}

@media screen and (max-width: 470px) {
  .details_section {
    margin-left: inherit;
  }
  .aside_container {
    width: inherit;
  }
  .css-cpk47q-MuiGrid-root {
    width: inherit;
  }
  .css-1gcnetv {
    width: inherit;
  }
}

.tournament_container {
  flex-wrap: wrap;
}

.tournaments_page_header {
  background-image: url("../../../assets/images/my-reservation.jpg");
  height: 23vh;
  background-attachment: fixed;
  position: relative;
}
.tournaments_page_header h1 {
  position: absolute;
  top: 7vh;
  left: 11vw;
  color: white;
}

.site-filters.center [class*="site-button"] {
  margin: inherit !important;
}
.site-filters li {
  margin: 2px;
}

.pas_de_tournois h1 {
  line-height: 2;
}
