.logout_btn {
  background-color: var(--color-primary);
  color: white;
}
.logout_btn:hover {
  background-color: #556893;
  border-color: #556893;
}

.edit_profile {
  cursor: pointer;
}

.custom-break-line-1 {
  display: block;
  margin: 0.5rem;
}

.custom-break-line-2 {
  display: block;
  margin: 1rem;
}

.custom-break-line-3 {
  display: block;
  margin: 1.5rem;
}
