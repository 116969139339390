.details_section {
  margin: 15px 40px;
}


.tournament-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* Ensures better spacing */
  padding: 10px;
  margin-top: 10px;
  gap: 10px;
  border-radius: 8px;
}

.tournament-title {
  font-style: normal;
  font-weight: 800;
  font-size: 22px; /* Reduce size for better fit */
  line-height: 26px;
  letter-spacing: 0.1em;
  padding: 5px 15px;
  border-radius: 4px;
  max-width: 100%;
  overflow: hidden;
  color: #000000;
}

.tournament-state {
  border-radius: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.14em;
  color: #000000;
  padding: 6px 16px;
  text-align: center;
}

.tournament-subtitle {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.14em;

  color: #000000;
  margin-top: 30px;
}
.tournament-p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px; /* Slightly increase for better readability */
  line-height: 140%;
  letter-spacing: 0.08em;
  color: #333; /* Darker color for readability */
  padding: 8px 12px;
  border-radius: 4px;
  overflow: hidden;
  text-align: left;
  max-width: 90%;
  margin-top: 10px;
  white-space: normal;
}

.tournament-details {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  letter-spacing: 0.14em;

  color: #000000;
}

.tournament-players {
  margin: 5px 0px;
}

.team-avatar {
  display: flex;
  flex-direction: row !important;
}

.tournois_header_title {
  color: white;
}
.tournois_header_title:hover {
  color: black;
}

.modal.show .modal-dialog {
  width: fit-content;
}
.tournament-details table {
  margin-top: 10px;
  margin-left: 10%;
}

@media screen and (max-width: 600px) {
  .details_section {
    margin: 15px 10px;
  }
  .tournament-details table {
    margin: 0;
  }
  .tournaments_page_header {
    height: 17vh;
  }
  .tournament-heading {
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
  }
}
