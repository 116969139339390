.card-title {
  font-weight: bold;
  font-size: 1.75rem;
}
.jetons {
  color: var(--color-primary);
  font-weight: bold;
  font-size: 3rem;
}
.prix {
  color: var(--color-secondry);
  font-weight: bold;
  font-size: 1rem;
  margin-top: 10%;
}
/* .card-body {
  background-color: #cbcb530f;
  box-shadow: 0px 0px 2px 0px var(--color-primary);
} */

.pack_offer {
  width: 290px;
  height: 290px;
  margin: auto;
  border-radius: 6px;
  transition: 0.4s;
}

.pack_offer:hover {
  border: 2px solid var(--color-primary);
  width: 300px;
  height: 300px;
  cursor: pointer;
  transition: 0.4s;

  /* .text {
    font-size: 16px;
    margin: 5px auto;
    color: #666666;
    font-weight: 400;
    overflow: hidden;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    line-clamp: 6;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-width: 300px;
  } */
}
